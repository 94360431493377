import React from "react"
import { Container } from "react-bootstrap"

import Layout from "../components/Layout"

export default () => (
  <Layout>
    <Container>
      <h1 className="my-4 cl-identity">Obrigada!</h1>
      <p>
        Seu pedido foi recebido com sucesso! ❤
      </p>
    </Container>
  </Layout>
)
